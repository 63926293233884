// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookiepolicy-js": () => import("./../../../src/pages/cookiepolicy.js" /* webpackChunkName: "component---src-pages-cookiepolicy-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-accessibility-statement-js": () => import("./../../../src/templates/AccessibilityStatement.js" /* webpackChunkName: "component---src-templates-accessibility-statement-js" */),
  "component---src-templates-activities-js": () => import("./../../../src/templates/Activities.js" /* webpackChunkName: "component---src-templates-activities-js" */),
  "component---src-templates-activity-group-js": () => import("./../../../src/templates/ActivityGroup.js" /* webpackChunkName: "component---src-templates-activity-group-js" */),
  "component---src-templates-activity-js": () => import("./../../../src/templates/Activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-community-guidelines-js": () => import("./../../../src/templates/CommunityGuidelines.js" /* webpackChunkName: "component---src-templates-community-guidelines-js" */),
  "component---src-templates-facilitation-article-js": () => import("./../../../src/templates/FacilitationArticle.js" /* webpackChunkName: "component---src-templates-facilitation-article-js" */),
  "component---src-templates-facilitation-js": () => import("./../../../src/templates/Facilitation.js" /* webpackChunkName: "component---src-templates-facilitation-js" */),
  "component---src-templates-get-bricks-js": () => import("./../../../src/templates/GetBricks.js" /* webpackChunkName: "component---src-templates-get-bricks-js" */),
  "component---src-templates-holistic-skill-js": () => import("./../../../src/templates/HolisticSkill.js" /* webpackChunkName: "component---src-templates-holistic-skill-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-learning-through-play-js": () => import("./../../../src/templates/LearningThroughPlay.js" /* webpackChunkName: "component---src-templates-learning-through-play-js" */),
  "component---src-templates-my-profile-js": () => import("./../../../src/templates/MyProfile.js" /* webpackChunkName: "component---src-templates-my-profile-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/PrivacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */)
}


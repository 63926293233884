import React from "react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { b2cPolicies, msalConfig } from "./authConfig";
import { CustomNavigationClient } from "./utils/NavigationClient";

const msalInstance = new PublicClientApplication(msalConfig);

const WrapRootElement = ({ element }) => {
  const navigationClient = new CustomNavigationClient();
  msalInstance.setNavigationClient(navigationClient);

  msalInstance.addEventCallback(message => {
    if (
      message.eventType === EventType.LOGIN_FAILURE &&
      message.error.errorMessage.includes("AADB2C90118")
    ) {
      // The user has forgotten their password.
      const { forgotPassword } = b2cPolicies.authorities;
      msalInstance.loginRedirect(forgotPassword);
    }
  });

  return <MsalProvider instance={msalInstance}>{element}</MsalProvider>;
};

export default WrapRootElement;

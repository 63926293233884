import React from "react";
import WrapRootElement from "./src/WrapRootElement";
import "./static/fonts/fonts.css";
import "./src/base.css";

export const onInitialClientRender = () => {
  /*
    The tabindex="-1" attribute on the gatsby-focus-wrapper element
    breaks focusing when using screen readers, so we remove it.
  */
  const focusWrapper = document.getElementById("gatsby-focus-wrapper");
  focusWrapper.removeAttribute("tabIndex");
};

export const wrapRootElement = WrapRootElement;

const theme = {
  fonts: {
    body: "Cera Pro",
  },
  breakpoints: ["768px", "1025px"],
  fontSizes: [16, 18, 22, 32, 42],
  lineHeights: ["22px", "26px", "28px", "40px", "52px"],
  colors: {
    background: "#FFFFFF",
    text: "#333333",
    lbbBlack: "#333333",
    lbbRed: "#B40000",
    lbbBlue: "#469BC3",
    lbbGreen: "#A5CA18",
    lbbYellow: "#FAC80A",
    lbbPurple: "#4D3191",
    brailleCardBackground: "#FFD8D8",
    preBrailleCardBackground: "#D1F0FE",
    lightGray: "#F7F7F7",
    gray: "#777777",
    separator: "#777777",
  },
  text: {
    h1: {
      fontSize: 4,
      lineHeight: 4,
      fontWeight: "bold",
      marginTop: 0,
      marginBottom: "35px",
    },
    h2: {
      fontSize: 3,
      lineHeight: 3,
      fontWeight: "bold",
      marginTop: 0,
      marginBottom: "30px",
    },
    h3: {
      fontSize: 2,
      lineHeight: 2,
      fontWeight: "bold",
      marginBottom: "20px",
    },
    h4: {
      fontSize: 2,
      lineHeight: 2,
      fontWeight: 500,
      margin: 0,
    },
    h5: {
      fontSize: 1,
      lineHeight: 1,
      margin: 0,
    },
    h6: {
      fontSize: 1,
      lineHeight: 2,
      margin: 0,
    },
    p: {
      fontSize: 1,
      lineHeight: 1,
    },
    li: {
      fontSize: 1,
      lineHeight: 1,
    },
    a: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "text",
      ":hover": {
        color: "lbbRed",
      },
      transition: "color 300ms",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: 400,
      bg: "background",
      color: "text",
    },
    h1: {
      variant: "text.h1",
    },
    h2: {
      variant: "text.h2",
    },
    h3: {
      variant: "text.h3",
    },
    h4: {
      variant: "text.h4",
    },
    h5: {
      variant: "text.h5",
    },
    h6: {
      variant: "text.h6",
    },
    p: {
      variant: "text.p",
      "@media print": {
        fontSize: "12pt",
      },
    },
    ul: {
      p: 0,
      listStyleType: "none",
    },
    li: {
      variant: "text.li",
      "@media print": {
        fontSize: "12pt",
      },
    },
    a: {
      variant: "text.a",
    },
    div: {
      "@media print": {
        marginBottom: "1rem",
      },
    },
  },
  grids: {
    print: {
      "@media print": {
        display: "grid",
        gridGap: 3,
        gridTemplateColumns: "repeat(4, 1fr)",
      },
    },
  },
};

export default theme;

// https://learn.microsoft.com/en-gb/azure/active-directory-b2c/enable-authentication-react-spa-app-options?tabs=popup
export const b2cPolicies = {
  names: {
    signUpSignIn: "b2c_1_susi",
    forgotPassword: "b2c_1_reset",
    editProfile: "b2c_1_edit_profile",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://auth.legobraillebricks.com/LegoBrailleBricks.onmicrosoft.com/B2C_1_signupsignin1",
    },
    forgotPassword: {
      authority:
        "https://auth.legobraillebricks.com/LegoBrailleBricks.onmicrosoft.com/B2C_1_password_reset",
    },
    editProfile: {
      authority:
        "https://auth.legobraillebricks.com/LegoBrailleBricks.onmicrosoft.com/B2C_1_profile_editing",
    },
  },
  authorityDomain: "auth.legobraillebricks.com",
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "feb6b22e-7704-486e-9cd5-39597cad97a5",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: ["LegoBrailleBricks.b2clogin.com", "auth.legobraillebricks.com"],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
